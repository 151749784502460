<template>
    <footer :class="{'anim': inview}">
        <div class="container">
            <div class="footer-content d-flex">
                <div class="footer-content--fill d-flex">
                    <div class="footer-img mob">
                        <div class="img-wrap">
                            <div class="rotated">
                                <img src="@/assets/images/footer-img.png" alt="footer image" />
                            </div>
                        </div>
                    </div>
                    <div class="left">
                        <img src="@/assets/images/logo-2.png" alt="footer-logo" />
                        <div class="left__text d-flex column">
                            <div class="text-s"><p>Quijano Chambers, P.O. Box 3159, Road Town, Tortola, British Virgin Islands</p></div>
                            <div class="text-s"><a href="mailto:info@falkoned.com">info@falkoned.com</a></div>
                            <div class="text-s"><a target="_blank" href="https://www.linkedin.com/company/falkoned/">Linkedin</a></div>
                        </div>
                    </div>
                    <div class="right">
                        <form class="d-flex column" id="form-2">
                            <div class="text-s"><p>Any questions?</p></div>
                            <div class="d-flex column inputs">
                                <div class="input-container" :class="{'error': isError && data.name.toString().trim().length == 0}">
                                    <input class="input" type="text" v-model="data.name" @input="handleInput" required maxlength="100" />
                                    <label class="placeholder" :class="{'hide': data.name !== ''}">Name</label>
                                </div>
                                <div class="input-container" :class="{'error': isError && !emailRegex.test(data.email)}">
                                    <input class="input" type="email" v-model="data.email" pattern="^.+@.+\.[a-zA-Z]{2,63}$" required maxlength="250"/>
                                    <label class="placeholder" :class="{'hide': data.email !== ''}">E-mail</label>
                                </div>
                                <div class="input-container">
                                    <textarea class="input" v-model="data.message"></textarea>
                                    <label class="placeholder" :class="{'hide': data.message !== ''}">Your message</label>
                                </div>
                            </div>    
                            <Button round color="#fff" text="Reach Us Out" @click="send"></Button>
                        </form>
                        <div class="bottom-text mobile d-flex">
                            <p class="text-s weight-400">©2023</p>
                            <RouterLink to="privacy" class="text-s weight-400">Privacy Policy</RouterLink>
                        </div>
                    </div>
                </div>
                <div class="footer-content--second d-flex align-end justify-center">
                    <div class="bottom-text d-flex">
                        <p class="text-s weight-400">©2023</p>
                        <RouterLink to="privacy" class="text-s weight-400">Privacy Policy</RouterLink>
                    </div>
                    
                    <div class="footer-img">
                        <div class="img-wrap">
                            <div class="rotated">
                                <img src="@/assets/images/footer-img.png" alt="footer image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="state.isAnotherComponentCalled" :key="isSent" :parentSuccess="isSent && isSuccess" :parentCall="state.isAnotherComponentCalled" @close="isSent = false"></Modal>
    </footer>
</template>

<script setup>
import {ref} from "vue";
import Button from './Button.vue';
import Modal from "./Modal.vue";
import { state, setAnotherComponentCalled } from '@/components/componentStore.js';

const data = ref({
    name: "",
    email: "",
    message: ""
})

const isSent = ref(false);
const isSuccess = ref(false);
const props = defineProps({
    inview: Boolean
})

const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const validate = () => {
    let error = false;
    Object.entries(data.value).map(([key, value]) => {
        if(key == 'name' && value.toString().trim().length == 0) {
            error = true;
            data.value.name = "";
        }
        if(key === 'email' && !emailRegex.value.test(value)) error = true;
    });
    return error;
}

const handleInput = (event) => {
    data.value.name = event.target.value;
    if(isError.value) validate();
}

const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
}

const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        isSuccess.value = true;
        isSent.value = true;
        setAnotherComponentCalled(true);
        document.body.classList.add('no-scroll')
        
        resetForm();
    })
    .catch(e => {
        isSuccess.value = false;
        isSent.value = true;
        setAnotherComponentCalled(true);
        document.body.classList.add('no-scroll')
        resetForm();
    })
}

const send = () => {
    let form = document.getElementById('form-2');
    isError.value = validate();
    form.reportValidity();
    if(!isError.value) {
        const formdata = new FormData();
        formdata.append('id', 'fireflyad-technologies');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@fireflyad.tech");
        formdata.append('message', data.value.message);
        formdata.append('subject', data.value.subject);

        fetchData(formdata)
    }
}


</script>

<style lang="scss" scoped>
footer {
    overflow: hidden;
    margin-top: 80px;
    position: relative;
    z-index: 10;
    &.anim {
        animation: view 1s ease forwards;
        .footer-content {
            &--fill {
                transform: translateY(100%);
                -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -o-transform: translateY(100%);
                animation: flow 1.3s ease forwards;
            }
            .left {
                .text-s p {
                    transform: translateY(200%);
                    -webkit-transform: translateY(200%);
                    -moz-transform: translateY(200%);
                    -o-transform: translateY(200%);
                    animation: flow 1.3s ease forwards;
                    animation-delay: 1.4s;
                }
                .text-s a {
                    transform: translateY(100%);
                    -webkit-transform: translateY(100%);
                    -moz-transform: translateY(100%);
                    -o-transform: translateY(100%);
                    animation: flow 1.5s ease forwards;
                    animation-delay: 1.4s;
                }
                a {
                    color: #fff !important;
                    &:visited, &:focus {
                        color: #fff !important;
                    }
                }
            }
            .right {
                .text-s p {
                    transform: translateY(100%);
                    -webkit-transform: translateY(100%);
                    -moz-transform: translateY(100%);
                    -o-transform: translateY(100%);
                    animation: flow 1s ease forwards;
                    animation-delay: 1.8s;
                }
                form .input-container {
                    overflow: hidden;
                    animation: view 5s ease forwards;
                    opacity: 0;
                    label {
                        transform: translateY(80px);
                        -webkit-transform: translateY(80px);
                        -moz-transform: translateY(80px);
                        -o-transform: translateY(80px);
                        animation: flow 1s ease forwards;
                        animation-delay: 1.5s;
                    }
                }
                .button {
                    opacity: 0;
                    animation: show 1s ease forwards;
                    animation-delay: 1.5s;
                }
            }
            &--second {
                .rotated {
                    transform: translateX(100%);
                    -webkit-transform: translateX(100%);
                    -moz-transform: translateX(100%);
                    -o-transform: translateX(100%);
                    animation: flow2 1s ease forwards;
                    animation-delay: 2s;
                }
                .bottom-text {
                    p, a {
                        transform: translateY(100%);
                        -webkit-transform: translateY(100%);
                        -moz-transform: translateY(100%);
                        -o-transform: translateY(100%);
                        animation: flow 1s ease forwards;
                        animation-delay: 2s;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
.mobile {
    display: none;
}
.footer-content{
    .input-container {
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }
    &--fill {
        position: relative;
        background: #0A0A35;
        padding: 80px 136px 48px 80px;
        color: #fff;
        gap: 64px;
        width: 75%;
    }
    & .left {
        img {
            margin-bottom: 32px;
        }
        &__text {
            gap: 16px;
        }
        .text-s {
            overflow: hidden;
        }
        a, a:visited, a:focus {
            color: #fff !important;
        }
    }
    & .right {
        .text-s {
            overflow: hidden;
        }
        form {
            width: 363px;
            gap: 28px;
            margin-top: 34px;
            & .inputs {
                gap: 12px;
            }   
        }
    }
    .mob {
        display: none;
    }
    &--second {
        position: relative;
        width: 25%;
        padding: 48px 0 48px 48px;
        // overflow: hidden;
        & p, a {
            color: #000;
            opacity: 0.5;
        }
        .footer-img {
            width: 20px;
            height: 100%;
            .img-wrap {
                position: absolute;
                right: -65%;
                bottom: 10%;
                overflow: hidden;
            }
            .rotated {
                width: 1em;
                height: 1em;
                font-size: 375px;
            }
            .rotated img {
                width: 100%;
                object-fit: cover;
                rotate: -60deg;
            }
        }
        .bottom-text {
            gap: 24px;
            overflow: hidden;
            width: 172px;
            a {
                color: #000 !important;
                &:visited, &:focus {
                    color: #000 !important;
                }
            }
        }
    }
}
a {
    text-decoration: underline;
    display: inline-block;
    white-space: nowrap;
}

@keyframes border-anim {
    to {
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    footer > .container {
        padding: 0;
    }
    .footer-content {
        &--fill {
            padding: 50px 50px;
            gap: 25px;
            width: 67%;
        }
        &--second {
            padding: 32px 0 32px 20px;
            width: 33%;
            .img-wrap {
                right: -120px !important;
            }
            .rotated {
                font-size: 330px !important;
            }
            .bottom-text {
                gap: 16px;
            }
        }
    }
}

@media (max-width: 768px) {
    footer {
        .container {
            padding: 0;
        }
    }
    form {
        width: 100% !important;
        max-width: 400px;
        margin-top: 8px !important;
    }
    .footer-content {
        &--second {
            display: none;
        }
        &--fill {
            width: 100%;
            flex-direction: column;
            overflow: hidden;
            padding: 40px !important;
            .left__text {
                flex-direction: row;
                flex-wrap: wrap;
                .text-s:first-child {
                    opacity: 0.5;
                    display: block;
                    max-width: 160px;
                    margin-right: 50%;
                    flex: 1 0 100%;
                }
                .text-s {
                    display: inline-block;
                    width: 45%;
                }
            }
        }
        .mobile {
            display: flex;
            margin-top: 48px;
            gap: 24px;
            opacity: 0.5;
            a, a:visited, a:focus {
                color: #fff !important;
            }
        }
        .mob {
            display: block;
            position: absolute;
            right: -80px;
            top: 0;
            
            .rotated {
                font-size: 280px;
                width: 1em;
                height: 1em;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    rotate: -60deg;
                }
            }
        }
    }
    .left img {
        width: 48px;
        height: 60px;
    }
    .left__text .text-s {
        font-weight: 400;
    }
}
@media (max-width: 425px) {
    .footer-content {
        .rotated {
            font-size: 224px !important;
        }
        .mob {
            right: -70px;
        }
        &--fill{
          padding: 35px 20px 30px !important;  
          & .left__text .text-s {
            width: auto;
          }
        }
    }
}
</style>