<template>
    <header>
        <div class="header-main" :class="{'fixed': isFixed}">
            <div class="container">
                <div class="header-content d-flex align-center justify-between">
                    <RouterLink to="/">
                        <div class="logo">
                            <img class="desktop" :src="require(`@/assets/icons/logo.svg`)" alt="logo" />
                            <img class="mob" :src="require(`@/assets/icons/mobile-logo.svg`)" alt="logo" />
                        </div>
                    </RouterLink>
                    <div class="actions d-flex align-center">
                        <div class="menu d-flex column align-end justify-center" @click="handleClick">
                            <div class="menu__item" :class="{'cross1': isMenuOpen || isModalOpen || state.isAnotherComponentCalled}"></div>
                            <div class="menu__item" :class="{'cross2': isMenuOpen || isModalOpen || state.isAnotherComponentCalled}"></div>
                            <div class="menu__item" :class="{'cross3': isMenuOpen || isModalOpen || state.isAnotherComponentCalled}"></div>
                        </div>
                        <Button round filled text="Contact Us" color="#0A0A35" @click="isModalOpen = true"></Button>
                    </div>
                </div>
            </div>
        </div>
        <Menu v-show="isMenuOpen" :isOpen="isMenuOpen" @close="isMenuOpen = false" :reset="reset"></Menu>
        <Modal v-if="isModalOpen" @close="isModalOpen = false" :reset="reset"></Modal>
    </header>
</template>

<script setup>
import {ref, watch, onMounted, onUnmounted} from "vue";
import Button from "@/components/Button.vue";
import Menu from "@/components/Menu.vue";
import Modal from "./Modal.vue";
import { state, setAnotherComponentCalled } from '@/components/componentStore.js';

const isMenuOpen = ref(false);
const isModalOpen = ref(false);
const isFixed = ref(false);
const reset = ref(false);
const headerHeight = 0;

watch(isMenuOpen, () => {
    if(!isMenuOpen.value) {
        isModalOpen.value = false;
        document.body.classList.remove('no-scroll');
    } else {
        document.body.classList.add('no-scroll');
    } 
}, {deep: true})

watch(isModalOpen, () => {
    if(!isModalOpen.value) {
        isMenuOpen.value = false;
        reset.value = true;
        document.body.classList.remove('no-scroll');
    } else {
        reset.value = false;
        document.body.classList.add('no-scroll');
    }
})

watch(state, () => {
    if(!state.isAnotherComponentCalled) document.body.classList.remove('no-scroll');
    else document.body.classList.add('no-scroll');
}, {deep: true})
const handleClick = () => {
    if(isMenuOpen.value && !state.isAnotherComponentCalled && !isModalOpen.value) {
        isMenuOpen.value = false;
    } else if(!isMenuOpen.value && !state.isAnotherComponentCalled && !isModalOpen.value) {
        isMenuOpen.value = true;
    } else if(state.isAnotherComponentCalled || isModalOpen.value) {
        isModalOpen.value = false;
        setAnotherComponentCalled(false);
        reset.value = true;
        document.body.classList.remove('no-scroll');
    }
}
const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    isFixed.value = scrollTop > headerHeight;
}
onMounted(() => {
    window.addEventListener('scroll', handleScroll);
})
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
})

</script>

<style lang="scss" scoped>
header {
    position: relative;
    z-index: 15;
    & .header-main.fixed {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
        width: 100%;
        background: #F4F3EE;
    }
}
.header-content {
    padding: 28px 0;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    .mob {
        display: none;
    }
}
.actions {
    gap: 26px;
}
.menu {
    gap: 3px;
    width: 36px;
    height: 36px;
    padding: 10px 6px;
    &:hover {
        cursor: pointer;
    }
    &__item {
        background: #0A0A35;
        height: 3px;
        transition: all .6s ease;
        &:nth-child(1) {
            width: 24px;
        }
        &:nth-child(2) {
            width: 20px;
        }
        &:nth-child(3) {
            width: 16px;
        }
        &.cross1 {
            transform: rotate(45deg) translateX(6px);
        }
        &.cross2 {
            width: 24px;
            transform: rotate(135deg) translate(-4px, -2px);
        }
        &.cross3 {
            opacity: 0;
        }
    }
}

@media (max-width: 768px) {
    .actions .button, .desktop {
        display: none;
    }
    .header-content {
        padding: 25px 0;
        & .mob {
            display: block;
        }
    }
}
</style>