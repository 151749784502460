<template>
    <div class="swiper-container" ref="swiperRef">
      <div class="swiper-title d-flex justify-between">
        <div class="text-xl" :class="{'anim': inview}"><p>Careers</p></div>
        <div class="btns d-flex align-center">
            <div class="swiper-button-next" @click="goToNextSlide" :class="{'disabled': swiperInstance?.isEnd}">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.9943 24C10.3512 24 8.80644 23.6856 7.36015 23.0568C5.91387 22.4356 4.63796 21.572 3.53242 20.4659C2.42688 19.3674 1.55987 18.0947 0.931377 16.6477C0.310459 15.2008 0 13.6515 0 12C0 10.3561 0.310459 8.81061 0.931377 7.36364C1.55987 5.91667 2.4231 4.64015 3.52106 3.53409C4.6266 2.42803 5.90251 1.56439 7.34879 0.943182C8.80265 0.314394 10.3512 0 11.9943 0C13.6375 0 15.1822 0.314394 16.6285 0.943182C18.0823 1.56439 19.3583 2.42803 20.4562 3.53409C21.5618 4.64015 22.4288 5.91667 23.0573 7.36364C23.6858 8.81061 24 10.3561 24 12C24 13.6515 23.6858 15.2008 23.0573 16.6477C22.4288 18.0947 21.5618 19.3674 20.4562 20.4659C19.3583 21.572 18.0861 22.4356 16.6398 23.0568C15.1936 23.6856 13.6451 24 11.9943 24ZM9.34785 17.8636C9.5523 18.0606 9.82868 18.1591 10.177 18.1591C10.5329 18.1515 10.8206 18.0417 11.0402 17.8295L15.7312 13.4432C16.0038 13.1856 16.1817 12.8826 16.265 12.5341C16.3559 12.1856 16.3559 11.8371 16.265 11.4886C16.1817 11.1402 16.0038 10.8409 15.7312 10.5909L11.0402 6.20455C10.7979 5.97727 10.514 5.86364 10.1884 5.86364C9.87033 5.85606 9.60151 5.95833 9.38192 6.17045C9.13204 6.39773 9.00331 6.68561 8.99574 7.03409C8.98817 7.375 9.10554 7.65909 9.34785 7.88636L13.7662 12.0114L9.34785 16.1477C9.11311 16.375 8.99195 16.6553 8.98438 16.9886C8.97681 17.322 9.09797 17.6136 9.34785 17.8636Z" />
              </svg>
            </div>
            <div class="swiper-button-prev" @click="goToPrevSlide" :class="{'disabled': swiperInstance?.isBeginning}">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12.0057 24C13.6488 24 15.1936 23.6856 16.6398 23.0568C18.0861 22.4356 19.362 21.572 20.4676 20.4659C21.5731 19.3674 22.4401 18.0947 23.0686 16.6477C23.6895 15.2008 24 13.6515 24 12C24 10.3561 23.6895 8.81061 23.0686 7.36364C22.4401 5.91667 21.5769 4.64015 20.4789 3.53409C19.3734 2.42803 18.0975 1.56439 16.6512 0.943182C15.1973 0.314394 13.6488 0 12.0057 0C10.3625 0 8.81779 0.314394 7.37151 0.943182C5.91765 1.56439 4.64174 2.42803 3.54378 3.53409C2.43824 4.64015 1.57123 5.91667 0.942736 7.36364C0.314245 8.81061 0 10.3561 0 12C0 13.6515 0.314245 15.2008 0.942736 16.6477C1.57123 18.0947 2.43824 19.3674 3.54378 20.4659C4.64174 21.572 5.91387 22.4356 7.36015 23.0568C8.80644 23.6856 10.3549 24 12.0057 24ZM14.6522 17.8636C14.4477 18.0606 14.1713 18.1591 13.823 18.1591C13.4671 18.1515 13.1794 18.0417 12.9598 17.8295L8.26881 13.4432C7.99621 13.1856 7.81827 12.8826 7.73497 12.5341C7.64411 12.1856 7.64411 11.8371 7.73497 11.4886C7.81827 11.1402 7.99621 10.8409 8.26881 10.5909L12.9598 6.20455C13.2021 5.97727 13.486 5.86364 13.8116 5.86364C14.1297 5.85606 14.3985 5.95833 14.6181 6.17045C14.868 6.39773 14.9967 6.68561 15.0043 7.03409C15.0118 7.375 14.8945 7.65909 14.6522 7.88636L10.2338 12.0114L14.6522 16.1477C14.8869 16.375 15.008 16.6553 15.0156 16.9886C15.0232 17.322 14.902 17.6136 14.6522 17.8636Z"/>
              </svg>
            </div>
        </div>
      </div>   
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, id) in swiper_items" :key="id" :id="`block${id+8}`">
          <div class="content d-flex column" :class="{'anim': blocks?.[id]?.visible && blocks?.[id]?.cnt==1}">
            <div class="text-l"><p>{{ id+1 }}. {{ item.title }}</p></div>
            <div class="text-s"><p>{{ item.text }}</p></div>
            <div class="text-s"><p class="weight-700">{{ id+1 }} — {{ swiper_items.length }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup>
import {ref, onMounted, onBeforeUnmount, nextTick, computed} from "vue";
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import {isElementVisible} from "@/visibility";

const blocks = ref(Array.from({ length: 6 }, () => ({ id: null, visible: false, cnt: 0 })));

const handleScroll = () => {
  blocks.value.map((block, index) => {
    const element = document.getElementById(block.id);
    if (element) {
      if((index < 3 && window.innerWidth > 425) || window.innerWidth <= 425) {
        const isVisible = isElementVisible(element, 0);
        if (isVisible && !block.visible) {
          block.visible = true;
          block.cnt++;
        } else if (!isVisible && block.visible) {
          block.visible = false;
        }
      }
    }
  })
};
  
SwiperCore.use([Pagination, Navigation]);

const props = defineProps({
  inview: Boolean
})

const swiperRef = ref(null);
let swiperInstance = null;

const handleDis = () => {
  if (swiperInstance.value) {
    swiperInstance.value.on('slideChange', () => {
      isBeginning.value = swiperInstance.value.isBeginning;
      isEnd.value = swiperInstance.value.isEnd;
    });
  }
}

const initializeSwiper = () => {
  if (swiperRef.value && !swiperInstance) {
    swiperInstance = new SwiperCore(swiperRef.value, {
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
    handleDis();
  }
};

onMounted(() => {
  initializeSwiper();
  blocks.value.forEach((block, index) => {
    block.id = `block${index + 8}`;
  });
  handleScroll()
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  if (swiperInstance) {
    swiperInstance.destroy();
  }
  window.removeEventListener('scroll', handleScroll);
});

const goToNextSlide = () => {
  if (swiperInstance && !swiperInstance.isEnd) {
    swiperInstance.slideNext();
  }
  handleDis();
};

const goToPrevSlide = () => {
  if (swiperInstance && !swiperInstance.isBeginning) {
    swiperInstance.slidePrev();
  }
  handleDis();
};

const swiper_items = ref([
    {
        title: "Innovation",
        text: "Falkoned celebrates creativity and embraces innovation. As part of our team, you'll have the opportunity to explore cutting-edge technologies and out-of-the-box solutions to create remarkable digital experiences for our clients."
    },
    {
        title: "Collaboration",
        text: "Teamwork lies at the heart of our success. Falkoned believes in the power of collaboration and values each team member's unique perspectives, talents, and expertise. Together, we achieve greatness and deliver exceptional results."
    },
    {
        title: "Customer-Centricity",
        text: "Our clients are at the center of everything we do. We believe in understanding their needs, challenges and aspirations to craft tailor-made solutions that exceed expectations and drive their success."
    },
    {
        title: "How do we measure success at our digital company?",
        text: "For us, success is not just about achieving our business goals; it's about helping our clients succeed. We measure our success by the tangible results and growth our clients experience through our digital solutions.their success."
    },
    {
        title: "What is our approach to handling challenges and setbacks?",
        text: "Challenges are opportunities for growth. We face setbacks with resilience and perseverance, using them as stepping stones to refine our strategies and deliver even better solutions to our clients."
    },
    {
        title: "How do we embrace adaptability in a constantly evolving digital landscape?",
        text: "The digital world is ever-changing, and we thrive on embracing new challenges. Our team is adaptable and quick to respond to emerging trends and technologies, ensuring that our clients always stay ahead in the digital realm."
    },
])
</script>

<style lang="scss" scoped>
.text-xl.anim {
    overflow: hidden;
    p {
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -o-transform: translateY(100%);
        animation: flow 0.7s ease forwards;
    }
}
.swiper-wrapper {
  align-items: stretch;
  height: 100%;
}
.content {
    height: 100%;
    .text-s:nth-child(2) {
      margin-bottom: 16px;
    }
    .text-s:nth-child(3) {
      margin-top: auto;
    }
}
.swiper-title {
    position: relative;
    .btns {
        width: 62px;
        gap: 8px;
        position: relative;
        height: 24px;
        --swiper-navigation-size: 24px;
        margin-top: 24px;
        .swiper-button-prev, .swiper-button-next {
          width: var(--swiper-navigation-size);
        }
    }
    .text-xl {
      margin-bottom: 42px;
    }
}

.swiper-slide {
    width: 512px !important;
    border-left: 1px solid rgba(0,0,0,0.1);
    padding: 36px 57px 36px 48px;
    height: initial;
    overflow: hidden;
    &:first-child {
        padding: 36px 105px 36px 0;
        border-left: none;
    }
    .text-l {
        margin-bottom: 35px;
    }
    .weight-700 {
        margin-top: auto;
    }
}
.swiper-button-next,
.swiper-button-prev {
    svg path {
      transition: fill 0.3s ease;
      fill: #1B3547;
    }
    &::after {
        content: none;
    }
}
.swiper-button-next {
    right: 0 !important;
}
.swiper-button-prev {
    left: 0 !important;
}
.swiper-button-next.disabled,
.swiper-button-prev.disabled {
  svg path {
    fill: rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    padding: 20px !important;
    width: 100% !important;
    &:first-child {
      padding: 20px 20px 20px 0 !important;
    }
  }
}
@media (min-width: 426px) {
  .content.anim {
    transform: translateX(150%);
    -webkit-transform: translateX(150%);
    -moz-transform: translateX(150%);
    -o-transform: translateX(150%);
    animation: flow2 1.5s ease forwards;
    animation-delay: 0.5s;
  }
}
@media (max-width: 425px) {
  .btns {
    display: none;
  }
  .swiper-wrapper {
    flex-direction: column;
    height: 100%;
  }
  .content.anim {
    .text-l, .text-s {
      overflow: hidden;
      p {
        transform: translateY(120%);
        -webkit-transform: translateY(120%);
        -moz-transform: translateY(120%);
        -o-transform: translateY(120%);
        animation: flow 1.5s ease forwards;
      }
    } 
  }
  .swiper-slide {
    padding: 20px 20px 20px 0 !important;
    border-left: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    .text-l {
      margin-bottom: 12px;
    }
  }
}
</style>
  