<template>
    <div class="bl" :class="{'anim': inview}">
        <div class="bl__img"><img src="@/assets/images/circle.png" alt="circle" /></div>
        <div class="leaves d-flex align-center justify-center">
            <div v-for="i in 3" :key="i" class="leaf leaf--big"></div>
        </div>
        <div class="bl__content d-flex column align-center">
            <div class="bl__title">
                <p class="text-l">{{ title1 }}</p>
                <p class="text-l" v-if="title2">{{ title2 }}</p>
                <p class="text-l mobile-text">{{ title1 }} {{ title2 ?? ''}}</p>
            </div>
            <div class="text-s"><p>{{ text }}</p></div>
            <Button class="desktop" color="#1B3547" text="Reach Us Out" @click="open = true, setAnotherComponentCalled(true)"></Button>
        </div>
    </div>
    <Button class="mobile" color="#1B3547" text="Reach Us Out" @click="open = true, setAnotherComponentCalled(true)"></Button>
    <Modal v-if="open && state.isAnotherComponentCalled" @close="open = false"></Modal>
</template>

<script setup>
import {ref, watch} from "vue";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import { state, setAnotherComponentCalled } from '@/components/componentStore.js';

const props = defineProps({
    title1: String,
    title2: String,
    text: String,
    inview: Boolean
})
const open = ref(false);
</script>

<style lang="scss" scoped>
.mobile {
    display: none;
}
.bl {
    position: relative;
    border-top: 1px solid rgba(0,0,0, 0.1);
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    overflow: hidden;
    &.anim {
        animation: view 1s ease forwards;
        .bl__content {
            .text-s p {
                transform: translateY(100%);
                -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -o-transform: translateY(100%);
                animation: flow 1s ease forwards;
                animation-delay: 0.7s;
            }
            .button {
                opacity: 0;
                animation: show 0.5s ease forwards;
                animation-delay: 1s;
            }
        }
        .bl__title {
            p {
                transform: translateY(190%);
                -webkit-transform: translateY(190%);
                -moz-transform: translateY(190%);
                -o-transform: translateY(190%);
                animation: flow 1.3s ease forwards;
            }
        }
        .bl__img img {
            transform: translateY(100%);
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -o-transform: translateY(100%);
            animation: flow 2s ease forwards;
        }
        .leaves .leaf {
            transform: translateY(100%);
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -o-transform: translateY(100%);
            animation: flow 1.8s ease forwards;
        }
    }
    &__content {
        padding: 50px 52px 50px;
        text-align: center;
        position: relative;
        z-index: 1;
        .text-s {
            margin: 30px 0 24px;
            width: 70%;
            overflow: hidden;
        }
    }
    &__title {
        overflow: hidden;
        .mobile-text {
            display: none;
        }
    }
    &__img {
        position: absolute;
        left: -100px;
        bottom: -220px;
        font-size: 403px;
        width: 1em;
        height: 1em;
        overflow: hidden;
        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .leaves {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        .leaf {
            border-radius: 0 180px 0 180px;
        }
    }
}
@keyframes show {
    to {
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    .leaf {
        max-width: 353px;
        max-height: 353px;
    }
}

@media (max-width: 768px) {
    .bl {
        overflow: visible;
        &__title {
            p:not(:last-child) {
                display: none;
            }
            .mobile-text {
                display: block;
                width: 90%;
            }
        }
        &__img {
            right: -150px;
            left: auto;
            bottom: auto;
            top: 0px;
            font-size: 300px;
            img {
                transform: translate(100%, 0);
                animation: flow2 1s ease forwards;
            }
        }
        &__content {
            padding: 34px 44px; 
            text-align: left;
            align-items: flex-start;
            .text-s {
                margin: 12px 0 0;
            }
        }
        .desktop {
            display: none;
        }
        .leaf {
            min-height: 380px;
            width: 380px !important;
            max-width: none;
            max-height: none;
        }
    }
    .mobile {
        display: block;
        margin: 30px auto 0;
    }
}

@media (max-width: 425px) {
    .bl {
        &__img {
            font-size: 240px;
        }
        &__content {
            padding: 34px 44px 22px;
            & .text-s {
                width: 100%;
            }
        }
        .leaf {
            height: 100% !important;
        }
    }
}
</style>