<template>
    <div class="menu-block">
        <div class="wrapper">
            <div class="container">
                <div class="menu-block__content">
                    <div class="menu-block__item">
                        <div class="menu-block__title text-xxl"><RouterLink to="/">Home</RouterLink></div>
                    </div>
                    <div class="menu-block__item" :class="{'no-border': isService}">
                        <div class="menu-block__title text-xxl d-flex align-center justify-center" @click="isService = !isService">
                            <a>Services</a>
                            <svg :class="{'rotated': isService}" xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                                <path d="M10.866 16.5C10.4811 17.1667 9.51887 17.1667 9.13397 16.5L0.47372 1.5C0.0888191 0.833334 0.569946 3.38737e-08 1.33975 1.01172e-07L18.6603 1.61538e-06C19.4301 1.68268e-06 19.9112 0.833336 19.5263 1.5L10.866 16.5Z" fill="black"/>
                            </svg>
                        </div>
                        <div class="over" v-if="isService">
                            <div class="d-flex align-center justify-center over-block">
                                <RouterLink to="/analytics" class="text-l weight-600">Analytics</RouterLink>
                                <RouterLink to="/contextual" class="text-l weight-600">Contextual advertising</RouterLink>
                                <RouterLink to="/email" class="text-l weight-600">Email marketing</RouterLink>
                                <RouterLink to="/seo" class="text-l weight-600">SEO</RouterLink>
                                <RouterLink to="/targeted" class="text-l weight-600">Targeted advertising</RouterLink>
                                <RouterLink to="/website" class="text-l weight-600">Website development</RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="menu-block__item">
                        <div class="menu-block__title text-xxl"><RouterLink to="/about">About us</RouterLink></div>
                    </div>
                    <div class="menu-block__item">
                        <div class="menu-block__title text-xxl"><RouterLink to="/careers">Career</RouterLink></div>
                    </div>
                    <div class="menu-block__item">
                        <div class="menu-block__title text-xxl"><RouterLink to="/contacts">Contacts</RouterLink></div>
                    </div>
                    <div class="menu-block__item">
                        <Button class="mobile" color="#1B3547" @click="reset=false, open = true, setAnotherComponentCalled(true)" text="Reach Us Out"></Button>
                        <div class="bottom d-flex align-center justify-center text-s">
                            <a target="_blank" href="https://www.linkedin.com/company/falkoned/">Linkedin</a>
                            <RouterLink to="/privacy">Privacy Policy</RouterLink>
                            <a href="mailto:info@falkoned.com">info@falkoned.com</a>
                        </div>
                    </div>
                    
                    <div class="menu-block__img">
                        <img src="@/assets/images/menu.png" alt="menu-image" />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="open && !reset" :reset="!open || reset" @close="reset=false, open = false, $emit('close'), setAnotherComponentCalled(false)"></Modal>
    </div>
</template>

<script setup>
import {ref, watch} from "vue";
import Button from "./Button.vue";
import Modal from "./Modal.vue";
import { state, setAnotherComponentCalled } from '@/components/componentStore.js';

const isService = ref(false);
const open = ref(false);
const props = defineProps({
    isOpen: Boolean,
    reset: Boolean
})

watch(() => {
    if(!props.isOpen) {
        setAnotherComponentCalled(false);
        isService.value = false;
    }
}, {deep: true})

</script>

<style lang="scss" scoped>
.menu-block {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    height: calc(100% - 106px);
    background: #F4F3EE;
    overflow: hidden;
    animation: flow4 0.6s ease forwards;
    &__content {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column; 
    }
    .wrapper {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        border-radius: 20px;
        position: relative;
        height: 100%;
        overflow: hidden;
        .container {
            overflow-y: auto;
            height: 100%;
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;
            &::-webkit-scrollbar {
                width: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0, 0.1);
            }
            &::-moz-scrollbar-thumb {
                background-color: rgba(0,0,0, 0.1);
            }
        }
    }
    a {
        text-decoration: none;
    }
    &__item {
        position: relative;
        z-index: 1;
        .text-xxl {
            font-size: 7vh;
            line-height: 8vh;
        }
        &:nth-child(5) {
            margin-bottom: 34px;
        }
        &:nth-child(6) {
            margin-top: auto;
        }
    }
    &__item:not(:nth-child(6)) {
        padding: 3vh 0 2vh; // 24px 0 16px
        border-bottom: 1px solid rgba(0,0,0, 0.1);
    }
    &__title {
        text-align: center;
        overflow: hidden;
        &:hover {
            cursor: pointer;
            a {
                text-decoration-line: underline;
            }
        }
        svg {
            margin-left: 24px;
            height: 100%;
            display: inline-block;
            transition: all .3s ease;
            transform: translateY(60px);
            -webkit-transform: translateY(60px);
            -moz-transform: translateY(60px);
            -o-transform: translateY(60px);
            animation: flow 0.8s ease forwards;
            animation-delay: 0.7s;
            &.rotated {
                rotate: 180deg;
            }
        }
        a, p {
            transform: translateY(100%);
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -o-transform: translateY(100%);
            animation: flow 0.8s ease forwards;
            animation-delay: 0.7s;
            display: block;
        }
        a {
            transition: all 0.4s ease;
        }
    }
    &__img {
        position: absolute;
        bottom: 180px;
        right: 70px;
        font-size: 385px;
        width: 1em;
        height: 1em;
        z-index: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .over {
        &-block {
            flex-wrap: wrap;
            width: 80%;
            margin: 16px auto;
            row-gap: 24px;
        }
        a {
            display: block;
            padding: 0 24px;
            opacity: 0.5;
            transition: opacity .3s ease;
            &:hover {
                opacity: 1;
            }
            &:not(:last-child) {
                border-right: 1px solid rgba(0,0,0, 0.1);
            }
        }
    }
    .bottom {
        gap: 15px;
        margin: 7.4vh 0 5vh; // 53px 0 36px
        flex-wrap: wrap;
        overflow: hidden;
        a {
            text-decoration: underline;
            transform: translateY(100%);
            display: inline-block;
            animation: flow 1s ease forwards;
            animation-delay: 0.7s;
        }
    }
    .mobile {
        display: none;
    }
}
a, a:visited, a:focus, a:active {
    color: #0A0A35 !important;
}
@media (max-width: 1200px) {
    .menu-block {
        &__img {
            font-size: 280px;
            right: 0;
        }
        .text-xxl {
            font-size: 6.3vh;
            line-height: 7vh;
        }
        .over-block {
            row-gap: 16px;
        }
    }
}
@media (max-width: 768px) {
    .menu-block {
        animation: flow5 0.6s ease forwards;
        height: calc(100% - 86px);
        .mobile {
            display: block;
            margin: 0 auto;
        }
        &__item.no-border {
            border-bottom: none;
            padding: 20px 0 16px;
        }
        &__item:not(:nth-child(6)) {
            padding: 20px 0;
        }
        &__title, &__title .d-flex {
            text-align: left;
            justify-content: flex-start;
        }
        &__img {
            font-size: 201px;
            top: 52px;
            right: -50px;
        }
        .over a:not(:last-child) {
            display: block;
            border-right: none;
        }
        .over-block {
            font-size: 30px;
            width: 100%;
            flex-direction: column;
            margin: 16px 0 0;
            align-items: flex-start;
            row-gap: 0;
            a {
                padding: 10px 0 16px;
                width: 100%;
                border-bottom: 1px solid rgba(0,0,0, 0.1);
            }
            .text-l{
                line-height: 44px;
            }
        }
        .text-xxl {
            font-size: 40px;
            line-height: 56px;
        }
        .bottom {
            margin: 30px 0 90px;
        }
    }
}
@media (max-width: 425px) {
    .menu-block__title svg {
        margin-left: 16px;
        width: 14px;
        height: 14px;
        &.rotated {
            rotate: -90deg;
        }
    }
    .menu-block__title:hover a{
        text-decoration: none;
    }
    .menu-block__title:active a{
        text-decoration: underline;
    }
    .menu-block__img {
        right: auto;
        left: 55%;
    }
}
</style>