<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,300;0,400;0,500;0,600;1,700&family=Space+Grotesk&display=swap');
@import "@/assets/scss/style.scss";
@import "@/assets/scss/media.scss";
@import "@/assets/scss/animation.scss";
.app {
  border-radius: 20px;
  background: #F4F3EE;
}
</style>
