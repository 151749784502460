import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: function () {
      return import('../views/AnalyticsView.vue')
    }
  },
  {
    path: '/seo',
    name: 'seo',
    component: function () {
      return import('../views/SeoView.vue')
    }
  },
  {
    path: '/email',
    name: 'email',
    component: function () {
      return import('../views/EmailMarketingView.vue')
    }
  },
  {
    path: '/website',
    name: 'website',
    component: function () {
      return import('../views/WebsiteView.vue')
    }
  },
  {
    path: '/contextual',
    name: 'contextual',
    component: function () {
      return import('../views/ContextualView.vue')
    }
  },
  {
    path: '/targeted',
    name: 'targeted',
    component: function () {
      return import('../views/SettingView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: function () {
      return import('../views/ContactsView.vue')
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: function () {
      return import('../views/CareerView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  document.body.classList.remove('no-scroll');
  window.scrollTo(0, 0);
})

export default router
