import { reactive } from 'vue';

const state = reactive({
  isAnotherComponentCalled: false,
});

const setAnotherComponentCalled = (value) => {
  state.isAnotherComponentCalled = value;
};

export { state, setAnotherComponentCalled };
