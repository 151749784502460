<template>
    <div class="button" :class="{'round': round, 'filled': filled}" :style="customStyle" @mouseover="handleHover(true)" @mouseout="handleHover(false)">
        {{ text }}
    </div>
</template>

<script setup>
import {ref, computed} from "vue";

const props = defineProps({
    filled: Boolean,
    round: Boolean,
    text: String,
    color: {
        type: String,
        default: "#1B3547"
    }
})
const isHovered = ref(false);

const handleHover = (value) => {
  isHovered.value = value;
};
const customStyle = computed(() => {
    const styles = {};
    if(props.round) {
        styles['border-radius']= '24px';
    }
    if(props.filled) {
        styles['background-color'] = isHovered.value ? 'rgba(10, 10, 53, 0.75)' : props.color;
        styles['color'] = '#fff';
    } else {
        styles['border'] = `1px solid ${props.color}`;
        styles['background-color'] = isHovered.value ? props.color !== '#fff' ? 'rgba(10, 10, 53, 0.75)' : '#fff' : '';
        styles['color'] = isHovered.value ? props.color !== '#fff' ? '#fff' : '#0A0A35' : props.color;
    }
    return styles;
})
</script>

<style lang="scss" scoped>
.button {
    padding: 16px;
    border-radius: 24px 24px 24px 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: min-content;
    white-space: nowrap;
    transition: all .3s ease;
    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .button {
        width: 100%;
        max-width: 400px;
        text-align: center;
        padding: 20.4px;
    }
}
</style>