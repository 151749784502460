<template>
    <div class="ui-modal">
        <div class="wrapper">
            <div class="container">
                <div class="ui-modal__content d-flex column align-center" :key="isSent">
                    <div class="ui-modal__title text-xxl d-flex align-center" :class="{'ans': isSent}">
                        <template v-if="!parentCall">
                            <p>{{ !isSent ? "Message Us" : isSuccess ? "Success!" : "Error!"}}</p>
                            <svg v-if="isSent && isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                            <path d="M11.1898 44C8.8427 44 7.05408 43.3917 5.82397 42.1751C4.60799 40.9726 4 39.2113 4 36.8912V11.1088C4 8.78868 4.60799 7.02741 5.82397 5.82493C7.05408 4.60831 8.8427 4 11.1898 4H36.8102C39.1573 4 40.9388 4.60831 42.1548 5.82493C43.3849 7.02741 44 8.78868 44 11.1088V36.8912C44 39.2113 43.3849 40.9726 42.1548 42.1751C40.9388 43.3917 39.1573 44 36.8102 44H11.1898ZM21.7306 34.4934C22.1548 34.4934 22.5437 34.3943 22.8971 34.1963C23.2648 33.9841 23.5758 33.6941 23.8303 33.3263L33.7349 18.0053C33.8904 17.7648 34.0177 17.5102 34.1166 17.2414C34.2298 16.9726 34.2863 16.7038 34.2863 16.435C34.2863 15.8408 34.0601 15.3599 33.6076 14.992C33.1693 14.6242 32.6674 14.4403 32.1018 14.4403C31.3524 14.4403 30.7232 14.8435 30.2142 15.6499L21.667 29.3156L17.7434 24.3926C17.4606 24.0389 17.1778 23.7913 16.895 23.6499C16.6122 23.4943 16.2941 23.4164 15.9406 23.4164C15.3468 23.4164 14.8448 23.6286 14.4348 24.0531C14.0247 24.4633 13.8197 24.9655 13.8197 25.5597C13.8197 25.8426 13.8692 26.1185 13.9682 26.3873C14.0813 26.6419 14.2368 26.9036 14.4348 27.1724L19.5461 33.3263C19.8572 33.7224 20.1895 34.0195 20.5429 34.2175C20.8964 34.4014 21.2923 34.4934 21.7306 34.4934Z" fill="#1E6C02"/>
                            </svg>
                            <svg v-if="isSent && !isSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                            <path d="M8.04043 44C6.81304 44 5.74424 43.7242 4.83404 43.1727C3.93763 42.635 3.24118 41.9042 2.74471 40.9804C2.24824 40.0703 2 39.0638 2 37.9607C2 36.899 2.27582 35.8856 2.82746 34.9204L18.8181 7.04033C19.3835 6.03378 20.1282 5.27542 21.0522 4.76525C21.99 4.25508 22.976 4 24.0103 4C25.0309 4 26.0031 4.25508 26.9271 4.76525C27.8511 5.27542 28.6027 6.03378 29.1819 7.04033L45.1725 34.9204C45.4484 35.403 45.6552 35.9062 45.7931 36.4302C45.931 36.9404 46 37.4505 46 37.9607C46 39.0638 45.7518 40.0703 45.2553 40.9804C44.7588 41.9042 44.0555 42.635 43.1453 43.1727C42.2489 43.7242 41.187 44 39.9596 44H8.04043ZM24.031 29.5429C25.355 29.5429 26.0445 28.8535 26.0997 27.4747L26.4307 17.6918C26.4582 17.0162 26.2376 16.4578 25.7687 16.0165C25.3136 15.5615 24.7275 15.334 24.0103 15.334C23.2794 15.334 22.6864 15.5546 22.2313 15.9959C21.7762 16.4371 21.5625 17.0024 21.59 17.6918L21.9003 27.4747C21.9555 28.8535 22.6657 29.5429 24.031 29.5429ZM24.031 36.8232C24.7757 36.8232 25.4101 36.5957 25.9342 36.1406C26.4582 35.6718 26.7203 35.072 26.7203 34.3413C26.7203 33.6243 26.4582 33.0314 25.9342 32.5626C25.4101 32.0938 24.7757 31.8594 24.031 31.8594C23.2725 31.8594 22.6312 32.0938 22.1072 32.5626C21.5831 33.0314 21.3211 33.6243 21.3211 34.3413C21.3211 35.072 21.5831 35.6718 22.1072 36.1406C22.645 36.5957 23.2863 36.8232 24.031 36.8232Z" fill="#B80000"/>
                            </svg>
                        </template>
                        <template v-else>
                            <p>{{ parentSuccess ? "Success!" : "Error!"}}</p>
                            <svg v-if="paretSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                            <path d="M11.1898 44C8.8427 44 7.05408 43.3917 5.82397 42.1751C4.60799 40.9726 4 39.2113 4 36.8912V11.1088C4 8.78868 4.60799 7.02741 5.82397 5.82493C7.05408 4.60831 8.8427 4 11.1898 4H36.8102C39.1573 4 40.9388 4.60831 42.1548 5.82493C43.3849 7.02741 44 8.78868 44 11.1088V36.8912C44 39.2113 43.3849 40.9726 42.1548 42.1751C40.9388 43.3917 39.1573 44 36.8102 44H11.1898ZM21.7306 34.4934C22.1548 34.4934 22.5437 34.3943 22.8971 34.1963C23.2648 33.9841 23.5758 33.6941 23.8303 33.3263L33.7349 18.0053C33.8904 17.7648 34.0177 17.5102 34.1166 17.2414C34.2298 16.9726 34.2863 16.7038 34.2863 16.435C34.2863 15.8408 34.0601 15.3599 33.6076 14.992C33.1693 14.6242 32.6674 14.4403 32.1018 14.4403C31.3524 14.4403 30.7232 14.8435 30.2142 15.6499L21.667 29.3156L17.7434 24.3926C17.4606 24.0389 17.1778 23.7913 16.895 23.6499C16.6122 23.4943 16.2941 23.4164 15.9406 23.4164C15.3468 23.4164 14.8448 23.6286 14.4348 24.0531C14.0247 24.4633 13.8197 24.9655 13.8197 25.5597C13.8197 25.8426 13.8692 26.1185 13.9682 26.3873C14.0813 26.6419 14.2368 26.9036 14.4348 27.1724L19.5461 33.3263C19.8572 33.7224 20.1895 34.0195 20.5429 34.2175C20.8964 34.4014 21.2923 34.4934 21.7306 34.4934Z" fill="#1E6C02"/>
                            </svg>
                            <svg v-if="!parentSuccess" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                            <path d="M8.04043 44C6.81304 44 5.74424 43.7242 4.83404 43.1727C3.93763 42.635 3.24118 41.9042 2.74471 40.9804C2.24824 40.0703 2 39.0638 2 37.9607C2 36.899 2.27582 35.8856 2.82746 34.9204L18.8181 7.04033C19.3835 6.03378 20.1282 5.27542 21.0522 4.76525C21.99 4.25508 22.976 4 24.0103 4C25.0309 4 26.0031 4.25508 26.9271 4.76525C27.8511 5.27542 28.6027 6.03378 29.1819 7.04033L45.1725 34.9204C45.4484 35.403 45.6552 35.9062 45.7931 36.4302C45.931 36.9404 46 37.4505 46 37.9607C46 39.0638 45.7518 40.0703 45.2553 40.9804C44.7588 41.9042 44.0555 42.635 43.1453 43.1727C42.2489 43.7242 41.187 44 39.9596 44H8.04043ZM24.031 29.5429C25.355 29.5429 26.0445 28.8535 26.0997 27.4747L26.4307 17.6918C26.4582 17.0162 26.2376 16.4578 25.7687 16.0165C25.3136 15.5615 24.7275 15.334 24.0103 15.334C23.2794 15.334 22.6864 15.5546 22.2313 15.9959C21.7762 16.4371 21.5625 17.0024 21.59 17.6918L21.9003 27.4747C21.9555 28.8535 22.6657 29.5429 24.031 29.5429ZM24.031 36.8232C24.7757 36.8232 25.4101 36.5957 25.9342 36.1406C26.4582 35.6718 26.7203 35.072 26.7203 34.3413C26.7203 33.6243 26.4582 33.0314 25.9342 32.5626C25.4101 32.0938 24.7757 31.8594 24.031 31.8594C23.2725 31.8594 22.6312 32.0938 22.1072 32.5626C21.5831 33.0314 21.3211 33.6243 21.3211 34.3413C21.3211 35.072 21.5831 35.6718 22.1072 36.1406C22.645 36.5957 23.2863 36.8232 24.031 36.8232Z" fill="#B80000"/>
                            </svg>
                        </template>
                    </div>
                    <form class="d-flex column" id="formm" v-if="!isSent && !parentCall">
                        <div class="d-flex column inputs">
                            <div class="input-container" :class="{'error': isError && data.name.toString().trim().length == 0}">
                                <input class="input" type="text" @input="handleInput($event, 'name')" v-model="data.name" required aria-required="true" maxlength="100"/>
                                <label class="placeholder" :class="{'hide': data.name !== ''}">Name</label>
                            </div>
                            <div class="input-container" :class="{'error': isError && !emailRegex.test(data.email)}">
                                <input class="input" type="email" v-model="data.email" required maxlength="250"/>
                                <label class="placeholder" :class="{'hide': data.email !== ''}">E-mail</label>
                            </div>
                            <div class="input-container">
                                <input class="input" type="text" v-model="data.subject"/>
                                <label class="placeholder" :class="{'hide': data.subject !== ''}">Message subject</label>
                            </div>
                            <div class="input-container">
                                <textarea class="input" v-model="data.message"></textarea>
                                <label class="placeholder" :class="{'hide': data.message !== ''}">Your message</label>
                            </div>
                        </div>  
                        <div class="d-flex column checkboxes">
                            <label class="custom-checkbox d-flex" for="ch-1" @click="isChecked = !isChecked" :key="isChecked">
                                <input type="checkbox" id="ch-1" :checked="isChecked" required name="terms"/>
                                <span class="checkmark"></span>
                                <span >I have read and accept the <router-link to="/privacy">Privacy Policy</router-link>.</span>
                            </label>
                            <label class="custom-checkbox d-flex" for="ch-2" @click="isChecked2 = !isChecked2" :key="isChecked2">
                                <input type="checkbox" id="ch-2" :checked="isChecked2"/>
                                <span class="checkmark"></span>
                                <span >I want to receive notifications about the latest products, events and cutting-edge ideas.</span>
                            </label>
                        </div>
                        <Button color="#1B3547" text="Send message" @click="send"></Button>
                    </form>
                    <div class="answer d-flex column align-center" v-else-if="isSent || parentCall">
                        <template v-if="isSent">
                            <div class="text-s" v-if="isSuccess">
                                <p>Your application has been accepted.</p>
                                <p>You will be contacted shortly for further details.</p>
                            </div>
                            <div class="text-s" v-if="!isSuccess">
                                <p>Something went wrong.</p>
                                <p>Please try again!</p>
                            </div>
                        </template>
                        <template v-if="parentCall">
                            <div class="text-s" v-if="parentSuccess">
                                <p>Your application has been accepted.</p>
                                <p>You will be contacted shortly for further details.</p>
                            </div>
                            <div class="text-s" v-if="!parentSuccess">
                                <p>Something went wrong.</p>
                                <p>Please try again!</p>
                            </div></template>
                        <Button color="#1B3547" text="Continue" @click="$emit('close'); setAnotherComponentCalled(false)"></Button>
                    </div>
                    <div class="ui-modal__bottom d-flex justify-center align-center">
                        <RouterLink to="/privacy" class="weight-500">Privacy Policy</RouterLink>
                        <a class="text-s" target="_blank" href="https://www.linkedin.com/company/falkoned/">Linkedin</a>
                        <a class="text-s" href="mailto:info@falkoned.com">info@falkoned.com</a>
                    </div>
                    <div class="ui-modal__img">
                        <img src="@/assets/images/menu.png" alt="modal-image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onBeforeUnmount, onUnmounted, watch} from "vue";
import Button from "./Button.vue";
import { setAnotherComponentCalled, state } from '@/components/componentStore.js';

const isError = ref(false);
const isChecked = ref(false);
const isChecked2 = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

const props = defineProps({
    parentSuccess: Boolean,
    parentCall: Boolean,
    reset: Boolean
})

const data = ref({
    name: '',
    email: '',
    message: '',
    subject: ''
});

const validate = () => {
    let error = false;
    Object.entries(data.value).map(([key, value]) => {
        if(key == 'name' && value.toString().trim().length == 0) error = true;
        if(key === 'email' && !emailRegex.value.test(value)) error = true;
    });
    return error;
}
const handleInput = (event,propertyName) => {
    data.value[propertyName] = event.target.value;
    if(isError.value) validate();
}
const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
    isChecked.value = false;
    isChecked2.value = false;
    isSent.value = false;
}
watch(props.parentCall, () => {
    if(!props.parentCall) resetForm();
})
watch(props.reset, () => {
    if(props.reset) resetForm();
})
watch(state, () => {
    if(!state.isAnotherComponentCalled) resetForm();
}, {deep: true}) 
const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        // resetForm();
    })
    .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        // resetForm();
    })
}

const send = () => {
    let form = document.getElementById('formm');
    isError.value = validate();
    form.reportValidity();
    if(!isError.value && isChecked.value) {
        const formdata = new FormData();
        formdata.append('id', 'falkoned');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@falkoned.com");
        formdata.append('message', data.value.message);
        formdata.append('subject', data.value.subject);

        fetchData(formdata)
    }
}
onBeforeUnmount(() => {
    resetForm();
    setAnotherComponentCalled(false);
})
onUnmounted(() =>{
    resetForm();
    setAnotherComponentCalled(false);
})
</script>

<style lang="scss" scoped>
.answer {
    text-align: center;
    animation: view 0.4s ease forwards;
    width: 100%;
    max-width: 350px;
    .button {
        margin-top: 32px;
    }
    .text-s {
        max-width: 267px;
    }
}
.ui-modal {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    width: 100%;
    height: calc(100% - 106px);
    background: #F4F3EE;
    overflow: hidden;
    animation: flow4 0.6s ease forwards;
    .wrapper {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        border-radius: 20px;
        position: relative;
        height: 100%;
        overflow: hidden;
        .container {
            overflow-y: auto;
            height: 100%;
            &::-webkit-scrollbar {
                width: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0, 0.1);
            }
            &::-moz-scrollbar-thumb {
                background-color: rgba(0,0,0, 0.1);
            }
        }
    }
    &__content {
        height: 97%;
        padding: 52px 0 34px;
        position: relative; 
    }
    &__title {
        margin-bottom: 24px;
        &.ans {
            margin-top: 134px;
        }
        svg{
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            margin-left: 12px;
        }
    }
    &__bottom {
        margin: auto 0 0;
        padding-bottom: 34px;
        column-gap: 16px;
        row-gap: 12px;
        a {
            font-size: 15px;
            line-height: 24px;
            color: #000;
            &:visited, &:focus {
                color: #000 !important;
            }
        }
    }
    &__img {
        position: absolute;
        bottom: 70px;
        right: -70px;
        font-size: 385px;
        width: 1em;
        height: 1em;
        z-index: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .checkboxes {
        margin-top: 24px;
        color: #fff;
        a, a:visited, a:active, a:focus {
            color: #000 !important;        }
    }
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 20px;
        width: 10px;
    }
    input, textarea {
        padding: 0 0 18px 0;
    }
    .input-container {
        border-bottom: 1px solid rgba(0,0,0, 0.1);
        &.error {
            border-bottom: 1px solid #E70000;
            color: #E70000;
        }
        input, textarea {
            color: #000;
        }
        .placeholder {
            left: 0;
        }
        & + .input-container {
            margin-top: 12px;
        }
    }
}
a:visited, a:focus, a:active {
    color: #000 !important;
}
form {
    max-width: 539px;
    margin-top: 10px;
    margin-bottom: 80px;
    .button {
        margin: 24px auto 0;
    }
}
.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  & span:nth-child(3) {
    padding-left: 12px;
    color: #000 !important;
    opacity: 0.5;
    width: 75%;
  }  
  &:hover {
    cursor: pointer;
    .checkmark{
        border: 1px solid rgba(39, 39, 39, 0.40);
    }
  }
  & + .custom-checkbox {
    margin-top: 14px;
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    border:1px solid rgba(0, 0, 0, 0.16);
    background: rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    background-size: 75% 75%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #1B3547;
    background-image: url("@/assets/icons/check1.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
  a {
    text-decoration: underline;
  }
}
@media (max-width: 1200px) {
    .ui-modal {
        &__img {
            font-size: 280px;
            right: 0;
        }
        .text-xxl {
            font-size: 6.3vh;
            line-height: 7vh;
        }
    }
}
@media (max-width: 768px) {
    .ui-modal {
        animation: flow5 0.6s ease forwards;
        height: calc(100% - 86px);
        &__img {
            font-size: 201px;
            top: auto;
            bottom: 110px;
            right: -60px;
        }
        &__title {
            margin-bottom: 24px;
            &.ans {
                margin-top: 140px;
            }
            svg{
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
            }
        }
        .text-xxl {
            font-size: 40px;
            line-height: 56px;
        }
        &__bottom {
            position: relative;
            z-index: 2;
            flex-wrap: wrap;
            padding-bottom: 90px;
            a {
                font-size: 14px;
                line-height: 34px;
            }
        }
        .checkboxes {
            margin-top: 12px;
        }
    }
    form {
        margin-top: 0;
    }
    form .button {
        margin: 34px auto 0;
    }
    .custom-checkbox + .custom-checkbox {
        margin-top: 12px;
    }
    .custom-checkbox span:nth-child(3) {
        width: 100%;
    }
}
@media (max-width: 425px) {
    .ui-modal__content {
        padding: 24px 0 0;
    }

    .ui-modal__img {
        right: auto;
        left: 65%;
    }
}
</style>